.home-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background-color: #282c34;
  color: white;
  padding: 0 20px;
  transition: margin-left 0.3s ease; // Smooth transition for content

  .text-zone {
    max-width: 600px;

    h1 {
      font-size: 3rem;
      color: #61dafb;
    }
    h2 {
      font-size: 1.5rem;
      margin-top: 10px;
      color: #ffffff;
    }
    .flat-button {
      margin-top: 20px;
      padding: 10px 20px;
      background-color: #61dafb;
      color: #282c34;
      text-decoration: none;
      border-radius: 5px;
      transition: background-color 0.3s;

      &:hover {
        background-color: #21a1f1;
      }
    }
  }

  .logo {
    img {
      width: 200px; // Adjust size
    }
  }

  &.sidebar-open {
    margin-left: 250px; // Adjust based on sidebar width
  }
}

.about-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #282c34;
  color: white;
  padding: 0 20px;

  .text-zone {
    max-width: 600px;

    h1 {
      font-size: 3rem;
      color: #61dafb;
      margin-bottom: 20px;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.5;
      color: #ffffff;
      margin-top: 10px;
    }
  }
}

.contact-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #282c34;
  color: white;
  padding: 0 20px;

  .text-zone {
    max-width: 600px;

    h1 {
      font-size: 3rem;
      color: #61dafb;
      margin-bottom: 20px;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.5;
      color: #ffffff;
      margin-top: 10px;
    }

    form {
      margin-top: 20px;

      .form-group {
        margin-bottom: 15px;

        label {
          display: block;
          margin-bottom: 5px;
        }

        input,
        textarea {
          width: 100%;
          padding: 10px;
          border: none;
          border-radius: 5px;
          background-color: #333;
          color: #fff;
        }

        textarea {
          height: 100px;
          resize: none;
        }
      }

      .flat-button {
        padding: 10px 20px;
        background-color: #61dafb;
        color: #282c34;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #21a1f1;
        }
      }
    }
  }
}

.nav-bar {
  position: fixed;
  top: 0;
  left: -250px; /* Initially hidden offscreen */
  height: 100%;
  width: 250px;
  background: linear-gradient(180deg, #282c34, #61dafb);
  color: white;
  padding: 20px;
  transition: transform 0.3s ease; /* Smooth transition for the sidebar */
  z-index: 1000;

  &.open {
    transform: translateX(250px); /* Move into view */
  }

  .profile {
    text-align: center;
    margin-bottom: 30px;

    .profile-pic {
      width: 70px;
      border-radius: 50%;
    }

    .profile-name {
      margin-top: 10px;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  nav {
    .toggle-icon {
      font-size: 2rem;
      cursor: pointer;
      margin-bottom: 20px;
      color: #ffd700; // Change color for better visibility
      position: absolute; // Position it absolutely
      top: 20px; // Adjust top position
      right: 20px; // Move to the right side
      z-index: 1001; // Ensure it's above other elements
    }

    .nav-links {
      display: flex;
      flex-direction: column;

      a {
        padding: 10px;
        text-decoration: none;
        color: white; // Link color
        display: flex;
        align-items: center;
        transition: background 0.3s, color 0.3s;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
          color: #ffd700; // Highlight color on hover
        }

        svg {
          margin-right: 10px;
        }
      }
    }
  }
}

.success-message {
  color: green;
}

.error-message {
  color: red;
}

